import React from "react";
import { Box, Button, Link, TextField, Typography, FormControlLabel, MenuItem, Select, FormControl, InputLabel } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { useNavigate, Link as RouterLink, useLocation } from "react-router-dom";
import { auth } from "../utils/firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import OverlayLoader from "../ui-components/loaders/OverlayLoader";
import AuthenticationLayout from "../ui-components/layouts/AuthenticationLayout";
import { RegistrationCard } from "../ui-components/cards/RegistrationCard";
import UserService from "../services/user-service";
import { useMutation } from "@tanstack/react-query";
import Checkbox from "@mui/material/Checkbox";
import { LOCAL_FILE_DOWNLOAD } from "../utils/constants";

const validationSchema = yup.object({
  email: yup.string().email("Enter a valid email").required("Email is required"),
  password: yup.string().min(8, "Password should be of minimum 8 characters length").required("Password is required"),
  name: yup.string().required("Name is required"),
  businessName: yup.string().required("Business name is required"),
  phoneNumber: yup.string().required("Phone number is required"),
  unitsPerMonth: yup.number().required("Units per month are required").positive().integer(),
});

const salesPeople = ["Anna", "Becky", "Brandon", "Cole", "Coleman", "Daniel","Frank", "Gary", "Jason", "Nathan", "Tyler"];

const Register = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const registerUser = useMutation({ mutationFn: (leadData) => UserService.selfRegisterLead(leadData) });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      name: "",
      businessName: "",
      phoneNumber: "",
      unitsPerMonth: "",
      referredToBy: "",
      trialDays: 14,
      acceptedTerms: false,
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        var mutationResult = await registerUser.mutateAsync(values);

        await signInWithEmailAndPassword(auth, values.email, values.password);

        if (location?.search?.includes("redirect")) {
          const redirectUrl = new URLSearchParams(location.search).get("redirect");
          navigate(redirectUrl);
          return;
        }

        navigate("/");
      } catch (error) {
        formik.setErrors({ email: "Invalid email or password", password: "Invalid email or password" });
      }
    },
  });

  return (
    <AuthenticationLayout>
      {formik.isSubmitting && <OverlayLoader />}
      <RegistrationCard>
        <Box>
          <Typography sx={{ fontSize: 34, fontWeight: 700, lineHeight: "123.5%", letterSpacing: "0.25px" }}>Register</Typography>
          <Typography sx={{ fontSize: 18, fontWeight: 400, lineHeight: "150%", letterSpacing: "0.15px", mt: 1.5 }}>Gain 14 days of exclusive access to the United Ecosystem.</Typography>
          <Box
            sx={{
              mt: { xs: 2, md: 4 },
            }}>
            <form onSubmit={formik.handleSubmit}>
              <TextField
                fullWidth
                id="businessName"
                name="businessName"
                label="Company Name"
                value={formik.values.businessName}
                onChange={formik.handleChange}
                error={formik.touched.businessName && Boolean(formik.errors.businessName)}
                helperText={formik.touched.businessName && formik.errors.businessName}
                required
                margin="dense"
              />
              <TextField
                fullWidth
                id="name"
                name="name"
                label="Your Name"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                required
                margin="dense"
              />
              <TextField
                fullWidth
                id="phoneNumber"
                name="phoneNumber"
                label="Phone Number"
                value={formik.values.phoneNumber}
                onChange={formik.handleChange}
                error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                required
                margin="dense"
              />
              <TextField
                fullWidth
                id="unitsPerMonth"
                name="unitsPerMonth"
                label="Cars Processed per Month"
                value={formik.values.unitsPerMonth}
                onChange={formik.handleChange}
                error={formik.touched.unitsPerMonth && Boolean(formik.errors.unitsPerMonth)}
                helperText={formik.touched.unitsPerMonth && formik.errors.unitsPerMonth}
                required
                margin="dense"
              />
              <FormControl fullWidth margin="dense">
                <InputLabel id="demo-simple-select-label">Who did you talk to?</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Who did you talk to?"
                  name="referredToBy"
                  value={formik.values.referredToBy}
                  required
                  onBlur={formik.handleBlur}
                  onChange={(e) => formik.setFieldValue("referredToBy", e.target.value)}>
                  <MenuItem value={"N/A"}>N/A</MenuItem>
                  {salesPeople.map((salesPerson) => (
                    <MenuItem key={salesPerson} value={salesPerson}>
                      {salesPerson}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                fullWidth
                id="email"
                name="email"
                label="E-Mail"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                margin="dense"
                required
              />
              <TextField
                fullWidth
                id="password"
                name="password"
                label="Password"
                type="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
                margin="dense"
                required
              />
              <FormControlLabel
                sx={{
                  mt: 2,
                }}
                onChange={(e) => formik.setFieldValue("acceptedTerms", e?.target?.checked)}
                control={<Checkbox />}
                label={
                  <Typography variant="body2">
                    I agree to the{" "}
                    <Link href={LOCAL_FILE_DOWNLOAD.EULA} target="_blank">
                      EULA
                    </Link>{" "}
                    and{" "}
                    <Link href={LOCAL_FILE_DOWNLOAD.PRIVACY_POLICY} target="_blank">
                      Privacy Policy
                    </Link>
                  </Typography>
                }
              />
              <Button sx={{ mt: 5, mb: 2 }} size="large" color="primary" variant="contained" fullWidth type="submit" disabled={formik.isSubmitting || formik.values.acceptedTerms == false}>
                <Typography sx={{ fontSize: 25, fontWeight: 600, lineHeight: "144.444%", letterSpacing: "0.639px" }}>REGISTER</Typography>
              </Button>
              <Typography color="error">{registerUser?.error?.response?.data?.responseException?.exceptionMessage}</Typography>
            </form>
          </Box>
        </Box>
      </RegistrationCard>
    </AuthenticationLayout>
  );
};

export default Register;
